import React from 'react'
import PropTypes from 'prop-types';
import VideoWrapper from './VideoWrapper';


const EmbedSource = (props) => (
    <iframe 
        {...props}
        frameborder="0" 
        loading="lazy"
    />
);


EmbedSource.propTypes = ({
    src : PropTypes.string.isRequired,
    title: PropTypes.string
})

export default EmbedSource