import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"

import tw from 'twin.macro'
import Container from './Container';
import SlideIndicators from './SlideIndicators';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';

import { H3 } from './Headings';

import useSlides from '../hooks/useSlides'
import { useSwipeable} from 'react-swipeable'

const useGraphics = () => {

    const data = useStaticQuery(graphql`{
        hospitalGraphic : allFile(filter: {relativePath: {eq: "building-1.png"}}) {
            nodes {
                childImageSharp {
                    fixed(width: 650, height: 573) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
        stakeGraphic : allFile(filter: {relativePath: {eq: "stake-alone.png"}}) {
            nodes {
                publicURL
                childImageSharp {
                    fixed(width: 212, height: 294) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
        stakeWithRaysGraphic : allFile(filter: {relativePath: {eq: "house-2.png"}}) {
            nodes {
                publicURL
                childImageSharp {
                    fixed(width: 212, height: 294) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
        zonesGraphic : allFile(filter: {relativePath: {eq: "building-1-sentricon.png"}}) {
            nodes {
                publicURL
                childImageSharp {
                    fixed(width: 650, height: 573) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }

    }`);

    const hospitalGraphic = data.hospitalGraphic.nodes[0].childImageSharp;
    const stakeStatic = data.stakeGraphic.nodes[0].publicURL;
    const stakeWithRaysStatic= data.stakeWithRaysGraphic.nodes[0].publicURL;
    const zonesStatic = data.zonesGraphic.nodes[0].publicURL;


    return ({
        hospitalGraphic,
        stakeStatic,
        stakeWithRaysStatic,
        zonesStatic,

    });
}


const SenticonHospital = ({ slides }) => {

    const {
        hospitalGraphic,
        stakeStatic,
        
        stakeWithRaysStatic,
        zonesStatic,

    } = useGraphics();

    const [currentSlide, slideControls] = useSlides(3);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            slideControls.nextSlide();
            // console.log(eventData);
        },
        onSwipedRight: (eventData) => {
            slideControls.prevSlide();
            // console.log(eventData)
        },
    });


    return (
        <>
        <section {...swipeHandlers} tw="relative w-full bg-yellow-600 py-10 overflow-x-hidden">
            {/* Make the top part of the section white; */}
            <div tw="hidden lg:(flex absolute w-full top-0 left-0 h-24 z-0 bg-white)" />

            <Container tw="md:(grid gap-8) lg:(grid-cols-2) z-10 px-5">
                <AnimateSharedLayout>
                    <motion.div 
                        initial={{opacity: 0}} 
                        animate={{opacity: 1}} 
                        exit={{opacity: 0}} 
                        layoutId="text-content" 
                        tw="z-10 flex justify-center content-center flex-col md:(py-10)"
                    >   

                        <AnimatePresence>
                            {Array.isArray(slides) && slides.map(({ content }, slideIndex) => (
                                <>
                                    {(currentSlide === (slideIndex + 1)) && (
                                        <motion.div
                                            exit={tw`opacity-0 hidden`}
                                        >

                                            <div tw="prose"
                                                
                                                dangerouslySetInnerHTML={{ __html: content }} 
                                            />
                                        </motion.div>
                                    )}
                                </>
                            ))}
                        </AnimatePresence>
                        
                        <div tw="sm:(hidden)">
                            <SlideIndicators currentSlide={currentSlide} setSlide={slideControls.setSlide} totalSlides={slideControls.totalSlides} />
                        </div>
                    </motion.div>
                </AnimateSharedLayout>
                
                
                {/*  */}
                <div
                    css={[
                        tw`z-10 relative mx-auto`,
                        // Handle mobile size
                        `transform: scale(0.6);
                        /* center the house */
                        left: calc((100% - 640px) / 4);

                        @media (min-width: 640px){
                            transform: scale(1);
                            left:0;
                        }`,
                    ]}
                >
                    <AnimatePresence>
                        {(currentSlide === 2) && (
                            <>
                                <motion.img
                                    initial={{opacity: 0, y: -100, position: 'absolute', zIndex: 50, x: -6 }}
                                    animate={{opacity: 1, y: 198}}
                                    exit={{opacity: 0}}
                                    src={stakeStatic} 
                                />
                                <motion.img
                                    initial={{opacity: 0, y: 198, position: 'absolute', zIndex: 50, x: -6 }}
                                    animate={{opacity: 1, }}
                                    transition={{delay: .3 }}
                                    exit={{opacity: 0}}
                                    src={stakeWithRaysStatic} 
                                />
                            </>
                        )}
                        
                    </AnimatePresence>
                    <AnimatePresence>
                        {(currentSlide === 3) && (
                            <>
                                <motion.img 
                                    initial={{
                                        opacity: 0, 
                                        zIndex: 40, 
                                        y: -30, 
                                        x: 30, 
                                        position: 'absolute', 
                                        top: 0, 
                                        bottom: 0, 
                                        left: 0, 
                                        right: 0,
                                        minWidth: `620px`,
                                    }}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    src={zonesStatic}
                                />
                            </>
                        )}
                    </AnimatePresence>
                    
                    <Img 
                        css={[
                            tw`z-40 absolute transition`,
                            `left: 45px;`,
                            (currentSlide === 2) && `
                                filter: sepia(75%) hue-rotate(7deg) opacity(60%); 
                                ${tw`opacity-25`}
                            `,
                        ]} 
                        fixed={hospitalGraphic.fixed} 
                    />

                </div>
            </Container>

            <div 
                css={[
                    tw`relative hidden sm:(flex) justify-center xl:(absolute)`,
                    `@media (min-width: 1280px) { 
                         right: 2rem; 
                         top: calc(50% - 2rem); 
                    }

                    `
                ]}
            >
                <SlideIndicators currentSlide={currentSlide} setSlide={slideControls.setSlide} totalSlides={slideControls.totalSlides} />
            </div>
            
        </section>
        
        </>
    )

}

export default SenticonHospital;