import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1, H2, H3, H4 } from '../../components/Headings'
import Container from '../../components/Container'
import { ButtonLink } from '../../components/Button'
import Testimonials from '../../components/Testimonials'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import SentriconHospital from '../../components/SentriconHospital'
import EmbedSource from '../../components/EmbedSource'
import VideoWrapper from '../../components/VideoWrapper'

const PageTemplate = ({ data }) => {
  const page = data.allWpPage.nodes[0]

  const {
    hero,
    slides,
    sentriconVideoSection,
    faq,
    footerCta,
  } = page.commercialTermiteServices

  const termitePatrol = data.termitePatrol.nodes[0].childImageSharp

  return (
    <>
      <SEO />
      <Layout headerType="commercial">
        {/* Hero */}
        <section aria-label="Pest Control Services" tw="pt-12">
          <Container tw="md:(grid grid-cols-4) gap-8">
            <div tw="col-span-3 text-gray-700 leading-relaxed">
              <H1>{hero.headline}</H1>
              <p tw="text-3xl">{hero.subHeadline}</p>
              <p tw="mb-8">{hero.paragraph}</p>
              <ButtonLink to="/quote" large>
                <span tw="mr-3">{hero.ctaButtonText}</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </ButtonLink>
            </div>
            <div tw="hidden md:(inline-block)">
              <Img fluid={termitePatrol.fluid} />
            </div>
          </Container>
        </section>

        {/* Diagram/Slideshow */}
        <SentriconHospital slides={slides} />

        <section aria-label="Pest Control Services">
          <Container>
            {/* Sentricon Video Section */}
            <div tw="flex justify-center flex-col content-center lg:(px-24 mx-24) py-16">
              <H2 tw="text-center text-4xl mb-5">Why Sentricon?</H2>
              <VideoWrapper>
                <EmbedSource
                  src={sentriconVideoSection.embed}
                  title="The Cooks Difference"
                  tw="z-10"
                />
              </VideoWrapper>
            </div>

            {/* FAQ */}
            {(faq?.questionList?.length || 0) > 0 && (
              <Container tw="grid grid-cols-1 gap-8 border-b-2 border-gray-200 pb-20">
                <H3 tw="text-5xl">{faq?.headline}</H3>
                <dl>
                  {faq?.questionList &&
                    faq.questionList.map(({ question, answer }) => (
                      <div tw="mb-12">
                        <dt tw="text-3xl mb-3">{question}</dt>
                        <dd tw="text-lg leading-relaxed text-gray-800">
                          {answer}
                        </dd>
                      </div>
                    ))}
                </dl>
              </Container>
            )}
          </Container>
        </section>

        {/* Testimonials */}
        <section tw="py-16">
          <Container>
            <Testimonials.Carousel />
          </Container>
        </section>

        {/* Footer CTA */}
        <section tw="py-16" aria-label="Get Your Free Estimate">
          <Container>
            {/* Footer CTA */}
            <div tw="text-center content-center pt-10">
              <H2 tw="text-5xl">{footerCta.headline}</H2>
              <p tw="text-lg leading-relaxed text-gray-800 lg:(px-10 mx-10) xl:(px-24 mx-24) mb-8">
                {footerCta.paragraph}
              </p>

              <ButtonLink to="/quote" large>
                <span tw="mr-3">{footerCta.ctaButtonText}</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </ButtonLink>
            </div>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    termitePatrol: allFile(
      filter: { relativePath: { eq: "termite-patrol.jpg" } }
    ) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }

    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        content
        commercialTermiteServices {
          hero: commercialTermitesHero {
            headline: commercialTermitesHeroHeadline
            subHeadline: commercialTermitesHeroSubHeadline
            paragraph: commercialTermitesHeroParagraph
            ctaButtonText: commercialTermitesHeroCtaButtonText
          }
          slides: commercialTermitesSlides {
            content: comercialTermitesSlideContent
          }
          sentriconVideoSection: commercialTermitesSentriconVideoSection {
            headline: commercialTermitesVideoHeadline
            embed: commercialTermitesVideoEmbed
            poster: commercialTermitesVideoPoster
          }
          faq: commercialTermitesFaq {
            headline: commercialTermitesFaqHeadline
            questionList: commercialTermitesFaqQuestionsList {
              answer: commercialTermitesAnswer
              question: commercialTermitesQuestion
            }
          }
          footerCta: commercialTermitesFooterCta {
            headline: commercialTermitesFooterHeadline
            paragraph: commercialTermitesFooterParagraph
            ctaButtonText: commercialTermitesFooterCtaButtonText
          }
        }
      }
    }
  }
`
