import React from 'react'
import tw, { styled } from 'twin.macro';

export const SlideIndicator = styled.button(({ selected }) => [
    tw`rounded-full w-4 h-4 border-2 bg-white border-white`,
    selected && tw`bg-opacity-0`
])

const SlideIndicators = ({ currentSlide, totalSlides, setSlide }) => {

    const slidePips = [...Array(totalSlides).keys()]
            .map(slide => ++slide);
    return (
        <nav aria-label="controls"> 
            <ul  tw="flex flex-row xl:(flex-col) justify-center content-center gap-3">
                {slidePips.map((slide)=>(
                    <li>

                        <SlideIndicator 
                            aria-label={(slide === currentSlide) ? `Current Slide, Slide ${currentSlide}`  : `Goto Slide ${slide}`}
                            onClick={() => {
                                setSlide(slide)
                            }} 
                            selected={(slide === currentSlide )}
                        />
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default SlideIndicators