import React from "react"
import PropTypes from 'prop-types';
import styled from '@emotion/styled'

/*
    Custom Video Wrapper that handles the proptions of a video.  
    Adapted to accept different aspect ratios.

    Based on this guide.
    https://css-tricks.com/fluid-width-video/

    written by Chris Coyier
    https://css-tricks.com/author/chriscoyier/
*/

const VideoWrapper = ({ ratio, children }) => {

    // set the ratio of the video.
    // can be used for standard 16:9, or other constraints, like mobile video;
    const [width, height] = ratio || [16, 9];
    
    const Wrapper = styled('div')`
        position: relative;
        padding-bottom: ${((height / width) * 100).toFixed(2)}%;
        height: 0;
        & > iframe,
        & > img,
        & > embed,
        & > object,
        & > picture {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    `
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

VideoWrapper.prototype = ({
    ratio : PropTypes.exact([PropTypes.number, PropTypes.number])
});

export default VideoWrapper;

