import { useState, useEffect } from 'react';



const useSlides = (numberOfSlides) => {
    const [slide, setSlideState] = useState(1);
    const [pause, setPause] = useState(false);

    const unpause = () => setPause(false);
    
    const setSlide = (slideNumber, activatePause = false) => {
        if (activatePause) setPause(true)
        if ((slideNumber + 1) >  numberOfSlides) {
            setSlideState(numberOfSlides);
        } else {
            setSlideState(slideNumber);
        }
    } 
    const nextSlide = (activatePause = false) => {
        if (activatePause) setPause(true)
        if ((slide + 1) >  numberOfSlides) {
            setSlideState(1);
        } else {
            setSlideState(slide + 1);
        }
    }

    const prevSlide = (activatePause = false) => {
        if (activatePause) setPause(true)
        if ((slide - 1) <= 0) {
            setSlideState(numberOfSlides);
        } else {
            setSlideState(slide - 1)
        }
    }

    useEffect(() => {
        clearTimeout(timeout);

        const timeout = setTimeout(nextSlide, 5000)

        return () => clearTimeout(timeout);
    },[ slide ])

    return [slide, ({
        prevSlide,
        nextSlide,
        pause: () => setPause(true),
        unpause,
        setSlide,
        totalSlides: numberOfSlides,
    })];
}

export default useSlides